<template>
    <div class="header">
        <div class="search_container">
            <div class="icon"></div>
            <input
            type="text"
            class="search"
            placeholder="Поиск..."
            v-model="search"
            @input="searchProducts(search)"
            >
            <div class="button search_button" :class="{clear:search.length}" @click="clearSearch(true);">
                <span v-if="!search.length">
                    Искать
                </span>
                <span v-if="search.length">
                    Удалить
                </span>
            </div>
        </div>
        <div class="buttons">
            <div class="button horizontal history">
                <p>
                    <span>
                        Перейти
                    </span>
                    <span>
                        История продаж
                    </span>
                </p>
            </div>
            <div class="button horizontal">
                <span>
                    Отчет ЛИБРО
                </span>
            </div>
            <div class="button square add_order" @click="toggleAddForm" ref="addButton">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup () {
        
        return {}
    },
    data() {
        return{
            search: '',
            time: null,
            addStatus: false,
        }
    },
    methods: {
        toggleAddForm() {
            this.$refs.addButton.classList.toggle('close');
            this.addStatus = !this.addStatus;
            this.emitter.emit('toggleAddForm', this.addStatus);
        },
        searchProducts(search) {
            if(this.time !== "undefined") {
                clearTimeout(this.time);
            }
            this.time = setTimeout(() => {
                this.emitter.emit('searchProducts', search);
            },500);
        },
        clearSearch(filter = false) {
            this.search = '';
            if(filter) {
                this.searchProducts(this.search);
            }
        }
    },
    created() {
        this.emitter.on('clearSearch', () => {
            this.clearSearch();
        })
    }
}
</script>

<style lang="scss" scoped>
    .header{
        display: flex;
        justify-content: space-between;
        margin: 1.2em 0 1.7em;
        .search_container{
            width: 53%;
            position: relative;
            .icon{
                position: absolute;
                top: 50%;
                left: 1em;
                width: 1.1em;
                height: 1.1em;
                transform: translateY(-50%);
                background-repeat: no-repeat;
                background-position: 50%;
                background-size: contain;
                background-image: url('~@/assets/svg/loop.svg');
            }
            .search_button{
                position: absolute;
                top: 50%;
                right: 0.7em;
                padding: 0.3em 1.2em;
                width: 6.5em;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(203,203,203,0.7);
                transform: translateY(-50%);
                transition: all 400ms ease-in-out;
                &.clear{
                    background-color: $redColor;
                    span{
                        color: white;
                    }
                }
                span{
                    font-weight: 400;
                }
            }
        }
        .search{
            padding: 1.25em 1em 1.25em 3em;
            border-radius: 14px;
            &:placeholder{
                color: #999999;
            }
        }
        .buttons{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 45%;
            .button{
                background-color: $buttonColor;
                border: none;
                &.horizontal{
                    border-radius: 50px;
                    padding: 0.7em 1.7em;
                    height: fit-content;
                    &:hover{
                        background-color: $darkGrey;
                    }
                }
                &.square{
                    width: 3em;
                    height: 3em;
                    background-color: $greenColor;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    background-image: url('~@/assets/svg/plus.svg');
                    background-size: 35%;
                    &.close{
                        background-color: $redColor;
                        background-image: url('~@/assets/svg/close.svg');
                    }
                }
                &.history{
                    p{
                        height: 20px;
                        overflow: hidden;
                        text-align: center;
                    }
                    span{
                        color: white;
                        display: block;
                        animation-name: change;
                        animation-duration: 10s;
                        animation-iteration-count: infinite
                    }
                }
                span{
                    letter-spacing: 0.03em;
                    color: white;
                    font-weight: 400;
                    font-size: 0.875em;
                }
            }
        }
    }
</style>
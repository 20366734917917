import { createApp } from 'vue'
import App from '@/App'
import {router} from '@/router'
import { store } from '@/store'
import VueVirtualScroller from 'vue-virtual-scroller'
import MaskedInput from "vue-masked-input";
import axios from 'axios'
import mitt from 'mitt'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
const emitter = mitt()
const app = createApp(App);

app.config.globalProperties.$http = axios;
const token = localStorage.getItem('token')
if (token) {
    app.config.globalProperties.$http.defaults.headers.common['Authorization'] = token
}

app.directive('mask', (el) =>  {
    el.setAttribute("maxlength", '14');
    function replaceNumberForInput(value) {
        value = value.replace(new RegExp("[^0-9]+", "g"), "");
        value = value.replace('380','');
        if(value[0] === '0') {
            value = value.substr(1);
        }
        let val = '';
        const x = value.match(/([0-9+]{0,2})([0-9+]{0,3})([0-9+]{0,2})([0-9+]{0,2})/);
        val = (x[1] ? '(' + x[1] :'') + (x[2].length?') '+x[2]:'') + (x[3] ? '-' + x[3] : '') + (x[4] ? '-' + x[4] : '');
        return val
    }
    function input() {
        setTimeout(() => {
            this.value = replaceNumberForInput(this.value);
        }, 30)
    }
    if(!el.classList.contains('masked')) {
        el.addEventListener('blur', input);
        el.addEventListener('input', input);
        el.classList.add('masked');
    }
})

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.webSocketLink = 'wss://api-tuda-suda.sigma-studio.pp.ua:3102/echo';
app.config.globalProperties.apiLink = 'https://api-tuda-suda.sigma-studio.pp.ua/';

app.use(VueVirtualScroller)
    .component('masked-input', MaskedInput)
    .use(router)
    .use(store)
    .mount('#app')

<template>
    <transition name="show">
        <div class="content" v-if="show">
            <div class="ccordinates" v-if="coordinates">
                {{coordinates.location.lat}}
            </div>
            <div class="map" id="map">

            </div>
            <div class="couriers">
                <!-- <div class="courier" v-for="courier in couriers" :key="courier.id">
                    <span class="name">
                        {{courier.name}}
                    </span>
                    <span class="telephone">
                        {{courier.telephone}}
                    </span>
                    <span class="status"></span>
                </div> -->
                <ul>
                    <li class="courier">
                        <div class="image">
                            <img src="@/assets/images/employees/2.jpg" alt="">
                        </div>
                        <div class="text">
                            <span class="name">
                                Дмитрий
                            </span>
                            <span class="post">
                                доставка
                            </span>
                        </div>
                    </li>
                    <li class="courier">
                        <div class="image">
                            <img src="@/assets/images/employees/3.jpg" alt="">
                        </div>
                        <div class="text">
                            <span class="name">
                                Виктория
                            </span>
                            <span class="post">
                                оператор
                            </span>
                        </div>
                    </li>
                    <li class="courier">
                        <div class="image">
                            <img src="@/assets/images/employees/4.jpg" alt="">
                        </div>
                        <div class="text">
                            <span class="name">
                                Маргарита
                            </span>
                            <span class="post">
                                оператор
                            </span>
                        </div>
                    </li>
                    <li class="courier">
                        <div class="image">
                            <img src="@/assets/images/employees/5.jpg" alt="">
                        </div>
                        <div class="text">
                            <span class="name">
                                Ирина
                            </span>
                            <span class="post">
                                оператор
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="close" @click="closeMap"></div>
        </div>
    </transition>
</template>

<script>
import * as Leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
import * as Orders from "@/api/Orders";
export default {
    props: [
        'coordinates'
    ],
    data() {
        return {
            show: false,
            placeIcon: require('@/assets/svg/Markers/1.svg'),
            map: null,
            marker: null,
            couriers: null,
            orderId: null,
        }
    },
    setup () {
        return {}
    },
    created() {
        this.emitter.on('openMap', (data) => {
            this.show = true;
            this.couriers = data.couriers;
            this.orderId = data.orderId;
            setTimeout(() => {
                this.map = Leaflet.map('map').setView([46.6508388,32.5963062], 13);
                Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(this.map);
                
                let placeIcon = Leaflet.icon({
                    iconUrl: this.placeIcon,
                    iconSize:     [40, 40],
                    iconAnchor:   [25, 35],
                    popupAnchor:  [-3, -76]
                });

                this.shopMarker = Leaflet.marker([46.6400852,32.593524], {icon: placeIcon}).addTo(this.map);

                for(let i = 0; i < this.couriers.length; i++) {
                    let courierMarker = Leaflet.icon({
                        iconUrl:      require('@/assets/svg/Couriers/'+i+'.svg'),
                        iconSize:     [50, 60],
                        iconAnchor:   [25, 35],
                        popupAnchor:  [-3, -76]
                    });
                    let c = i + 1;
                    let lat = 46.6281089 + (0.006 * c);
                    let lon = 32.6139675 + (0.006 * c);
                    this.couriers[i].marker = new Leaflet.marker([lat,lon], {icon: courierMarker});
                    this.couriers[i].marker.on('click', this.selectCourier(this.couriers[i].id))
                    .addTo(this.map);
                }

                // if(this.coordinates) {
                //     this.courierMarker = Leaflet.marker([this.coordinates.location.lat, this.coordinates.location.lon], {icon: courierMarker}).addTo(this.map);
                // }
                
                // this.emitter.on('changeMarkerPosition', (data) => {
                //     if(data) {
                //         let newLatLng = new Leaflet.LatLng(data.location.lat, data.location.lon);
                //         this.courierMarker.setLatLng(newLatLng);
                //     }
                // })
            }, 50);
            setTimeout(() => {
                //document.querySelector(".leaflet-bottom.leaflet-right").remove();
            }, 200);
        })
    },
    methods: {
        selectCourier(id) {
            return () => {
                let form = new FormData();
                form.append('courier', id);
                form.append('orderId', this.orderId);
                Orders.setCourier(form);
                this.closeMap();
            }
        },
        closeMap() {
            this.map.off();
            this.map.remove();
            this.show = false;
            //this.emitter.off('changeMarkerPosition');
        }
    },
    mounted() {
        
    }
}
</script>

<style lang="scss" scoped>
.content{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: 99999;
    transition: all 400ms ease-in-out;
    &.show-enter-active, &.show-leave-active{
        opacity: 0;
    }
}

.map{
    width: 100%;
    height: 100%;
}

.couriers{
    position: absolute;
    bottom: 2em;
    right: 2em;
    padding: 1.5em;
    border-radius: 10px;
    background-color: $darkGrey;
    z-index: 999999;
    ul{
        overflow: hidden;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .courier{
        display: flex;
        align-items: center;
        margin-bottom: 1.2em;
        &:last-child{
            margin-bottom: 0;
        }
        .image{
            width: 2.5em;
            height: 2.5em;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 1em;
        }
        .text{
            line-height: 1.2;
            span{
                display: block;
                font-weight: 500;
                &.name{
                    color: white;
                    font-size: 0.875em;
                }
                &.post{
                    color: #828282;
                    font-size: 0.75em;
                }
            }
        }
    }
}

.close{
    position: absolute;
    top: 1em;
    right: 1em;
    width: 3em;
    height: 3em;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 35%;
    border-radius: 50%;
    background-color: rgba(0,0,0,0.5);
    background-image: url("~@/assets/svg/close.svg");
    z-index: 99999;
}
</style>
<template>
    <transition name="show">
        <div class="order_container" v-if="show">
            <div 
            v-if="showProducts"
            class="top_container">
                <FilterHorizontal 
                ref="filterHorizontal"
                v-if="this.$store.state.catalog.status"
                @filterProducts="filterProducts($event)"
                :categories="this.$store.state.catalog.categories.category" />
                <ProdList
                v-if="this.$store.state.catalog.status"
                :products="filteredProducts"
                ref="prodList" />
            </div>
            <Order
            ref="order"
            :data="data"
            @changeStatus="changeStatus($event)"
            @closeOrder="closeOrder()"
            @editOrder="editOrder($event)"
            @addOrder="addOrder($event)"
            @toggleEdit="toggleEdit($event)" />
        </div>
    </transition>
</template>

<script>
import * as Orders from "@/api/Orders";
import {filter, searchProducts} from '@/plugins/Filter.js';
import Order from "@/components/Order";
import FilterHorizontal from "@/components/Container/FilterHorizontal";
import ProdList from "@/components/Container/ProdList";
export default {
    components: {
        Order,
        FilterHorizontal,
        ProdList,
    },
    setup () {
        return {}
    },
    data() {
        return{
            show: false,
            filteredProducts: [],
            showProducts: false,
            edit: false,
            status: '',
            data: {},
            defaultData: {
                telephone: '',
                firstname: '',
                street: '',
                house: '',
                entrance: '',
                persons: 0,
                admin: true,
                resource: 'operator',
                payment_method: 'cod',
                payment_type: 'Нал',
                delivery_time: 'Сейчас',
                comment: '',
                products: [],
                type: 'add',
                appType: 'operator',
            },
        }
    },
    methods: {
        openOrder() {
            this.show = true;
        },
        closeOrder() {
            this.show = false;
        },
        changeStatus(status) {
            this.closeOrder();
            let statusData = {
                'status': status,
                'orderId': this.data.order_id
            }
            Orders.editOrderStatus(statusData);
            if(status === '3') {
                this.$emit('openMap', this.data.order_id);
            }
        },
        filterProducts(data) {
            if(data.category_id === 0){
                this.filteredProducts = this.$store.state.catalog.products;
            }else {
                this.emitter.emit('clearSearch');
                this.filteredProducts = filter(data.category_id, this.$store.state.catalog.products);
            }
            setTimeout(() => {
                this.$refs.prodList.updateSlider();
            }, 600);
        },
        toggleEdit(status) {
            this.showProducts = status;
            this.edit = status;
        },
        async editOrder(data) {
            let form = {...data};
            form.products = JSON.stringify(form.products);
            let formData = new FormData();
            for (let key in form) {
                formData.append(key, form[key]);
            }
            await Orders.editOrder(formData);
            this.closeOrder();
            this.changeStatus('3');
        },
        async addOrder(data) {
            let form = {...data};
            form.products = JSON.stringify(form.products)
            let formData = new FormData();
            for (let key in form) {
                formData.append(key, form[key]);
            }
            let response = await Orders.addOrder(formData);
            this.show = false;
            this.$emit('openMap', response.order_id);
            document.querySelector(".header .add_order").classList.remove('close');
            form.products = [];
        }
    },
    created() {
        this.emitter.on('addCartProduct', (product) => {
            let addStatus = true;
            if(!this.data.products.length) {
                this.data.products.push(product);
                this.$refs.order.createProductSlider();
            }
            for(let i = 0; i < this.data.products.length; i++) {
                if(this.data.products[i].product_id !== product.product_id) {
                    addStatus = true;
                } else if(this.data.products[i].product_id === product.product_id && this.data.products[i].option !== product.option) {
                    addStatus = true;
                } else {
                    addStatus = false;
                    break;
                }
            }
            if(addStatus) {
                this.data.products.push(product);
            }
        })
        
        this.emitter.on('searchProducts', (text) => {
            this.$refs.filterHorizontal.filter(0,0);
            this.$refs.filterHorizontal.clearActive();
            this.filteredProducts = searchProducts(text, this.filteredProducts);
            setTimeout(() => {
                this.$refs.prodList.updateSlider();
            }, 600);
        })

        this.filteredProducts = this.$store.state.catalog.products;
        
        this.emitter.on('openOrder', (data) => {
            this.openOrder();
            this.showProducts = false;
            this.data = data;
            this.data.type = 'edit';
            if(parseInt(data.order_status_id) === 1) {
                let statusData = {
                    'status': 2,
                    'orderId': this.data.order_id
                }
                Orders.editOrderStatus(statusData);
            }
        });
        this.emitter.on('toggleAddForm', (status) => {
            this.showProducts = true;
            this.data = this.defaultData; 
            this.data.type = 'add';
            if(status) {
                this.openOrder();
            } else {
                this.closeOrder();
            }
        });
    }
}
</script>

<style lang="scss" scoped>
    .order_container{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $grey;
        transition: all 300ms linear;
        &.show-enter-active, &.show-leave-active{
            opacity: 0;
        }
    }
    .product_list{
        margin-top: 1.7em;
        padding-bottom: 1.7em;
        height: fit-content;
    }
</style>
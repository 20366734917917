<template>
    <div class="order" :class="{edit:canEdit}">
        <div class="order_content">
            <div class="fields">
                <div class="right_block">
                    <div class="input telephone">
                        <span class="label">
                            Телефон
                        </span>
                        <div class="input_tel_cont">
                            <input type="tel"
                            class="full_border"
                            ref="telephone"
                            @blur="mask(form.telephone)"
                            @input="mask(form.telephone);"
                            v-model="form.telephone">
                            <div class="placeholder">+380 <span v-if="!form.telephone.length">(99) 999-99-99</span></div>
                        </div>
                    </div>
                    <div class="input name">
                        <span class="label">
                            Имя
                        </span>
                        <input type="text" class="full_border" v-model="form.firstname">
                    </div>
                    <div class="input street">
                        <span class="label">
                            Улица
                        </span>
                        <input type="text" class="left_border" v-model="form.street">
                    </div>
                    <div class="input house">
                        <span class="label">
                            Дом
                        </span>
                        <input type="text" v-model="form.house">
                    </div>
                    <div class="input entrance">
                        <span class="label">
                            Подъезд
                        </span>
                        <input type="number" class="right_border" v-model="form.entrance">
                    </div>
                </div>
                <div class="left_block">
                    <div class="input payment_method">
                        <span class="label">
                            Оплата
                        </span>
                        <div class="input_payment_cont">
                            <!-- <div class="plus" v-if="data.type === 'add'" @click="togglePaymentMethods"></div>
                            <transition name="show">
                                <div class="payment_methods" v-if="data.type === 'add' && showMethods">
                                    <div class="method" v-for="method in paymentMethods" :key="method.code">
                                        <input type="radio" :id="method.code" v-model="form.payment_method">
                                        <label :for="method.code">
                                            <span class="filter_value_name">{{method.title}}</span>
                                        </label>
                                    </div>
                                </div>
                            </transition> -->
                            <input type="text" 
                            readonly
                            :value="form.payment_type" 
                            class="full_border" 
                            :class="{full_border: data.type === 'add'}">
                        </div>
                    </div>
                    <div class="input delivery_time">
                        <span class="label">
                            На время
                        </span>
                        <input
                        type="text"
                        readonly
                        :value="form.delivery_time"
                        @click="openSelectModal"
                        class="full_border">
                    </div>
                    <div class="input persons">
                        <span class="label">
                            Персоны
                        </span>
                        <input type="number" class="full_border" v-model="form.persons">
                    </div>
                    <div class="input comment">
                        <span class="label">
                            Комментарий
                        </span>
                        <textarea class="full_border" v-model="form.comment"></textarea>
                    </div>
                </div>
            </div>
            <div class="products swiper" v-if="data.products.length">
                <transition-group name="list" tag="div" class="swiper-wrapper">
                    <div class="product swiper-slide" 
                    v-for="product in products" 
                    :key="product.product_id">
                        <div class="image">
                            <img :src="product.image" alt="">
                        </div>
                        <div class="description">
                            <span class="name">
                                {{product.name}}
                            </span>
                            <div class="bottom_block">
                                <span class="price">
                                    {{product.price}} грн
                                </span>
                                <span class="option" v-if="product.option_value">
                                    {{product.option_value}} см
                                </span>
                                <span class="quan">
                                    x {{product.quantity}}
                                </span>
                            </div>
                        </div>
                        <Quantity 
                            @changeQuan="canEdit ? changeQuan($event, product.product_id, product.option) : ''"
                            @removeProduct="canEdit ? removeProduct(product.product_id, product.option) : ''"
                            :quantity="product.quantity"
                            :canEdit="canEdit" />
                    </div>
                </transition-group>
            </div>
            <div class="buttons">
                <div class="left_block">
                    <div class="to_archive">
                        <div class="button">
                            <div class="icon"></div>
                        </div>
                        <span class="order_id">{{data.order_id}}</span>
                    </div>
                </div>
                <div class="right_block">
                    <div class="total">
                        <span class="title">
                            Сумма: 
                        </span>
                        <span class="value">
                            ₴ {{calcTotal}}
                        </span>
                    </div>
                    <div class="button call"
                    :class="{cancel: canEdit}"
                    v-if="data.type === 'edit'"
                    @click="toggleEdit">
                        <span v-if="!canEdit">Дополнить</span>
                        <span v-if="canEdit">Отмена</span>
                    </div>
                    <div class="button add_product"
                        v-if="data.type === 'edit'"
                        @click="data.order_status_id == 2 ? changeStatus('1') : closeOrder() "
                    >
                        <span>Закрыть</span>
                    </div>
                    <div class="button success"
                        @click="data.type === 'edit' ? editOrder() : 
                        data.type === 'add' ? addOrder() : ''"
                        >
                        <div class="icon"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Quantity from '@/components/Products/Quantity'
    import Swiper from 'swiper';
    import 'swiper/swiper.min.css';
    export default {
        props: {
            data: {
                type: Object,
                default: function () {
                    return {
                        products: {}
                    }
                }
            },
            paymentMethods: {
                type: Array,
            }
        },
        data() {
            return {
                products: [],
                form: this.data,
                productSlider: null,
                showMethods: false,
                timeList: [
                    "Сейчас",
                    "11:00",
                    "11:30",
                    "12:00",
                    "12:30",
                    "13:00",
                    "13:30",
                    "14:00",
                    "14:30",
                    "15:00",
                    "15:30",
                    "16:00",
                    "16:30",
                    "17:00",
                    "17:30",
                    "18:00",
                    "18:30",
                    "19:00",
                    "19:30",
                    "20:00",
                    "20:30",
                    "21:00",
                    "21:30",
                    "22:00",
                    "22:30",
                    "23:00",
                ],
                canEdit: false,
            }
        },
        components: {
            Quantity,
        },
        setup () {
            return {}
        },
        computed: {
            calcTotal() {
                let total = 0;
                for(let i = 0; i < this.data.products.length; i++) {
                    total += parseInt(this.data.products[i].price, 10) * this.data.products[i].quantity;
                }
                return total;
            }
        },
        created() {
            this.emitter.on('updateSlider', () => {
                setTimeout(() => {
                    this.productSlider.updateProgress();
                    this.productSlider.updateSlides();
                    this.productSlider.update();
                    this.productSlider.updateSize();
                    this.productSlider.slideTo(this.productSlider.slides.length, 500);
                }, 300);
            })
            this.emitter.on('selectModalValue', (value) => {
                if(!value) {
                    this.form.sendTime = 'now';
                    this.time = 'час';
                    this.form.delivery_time = 'Сейчас';
                } else {
                    this.form.sendTime = 'time';
                    this.form.delivery_time = value;
                    this.time = value;
                }
            })
            for (let key in this.data) {
                this.form[key] = this.data[key];
            }
            this.products = this.data.products;
        },
        methods: {
            closeOrder() {
                this.$emit('closeOrder');
            },
            changeStatus(status) {
                this.$emit('changeStatus', status);
                this.$emit('closeOrder');
                if(status === '3') {
                    //this.emitter.emit('openMapHandler', this.data.order_id);
                }
            },
            // togglePaymentMethods() {
            //     if(this.showMethods) {
            //         this.showMethods = false;
            //         return;
            //     }
            //     this.showMethods = true;
            // },
            toggleEdit() {
                this.canEdit = !this.canEdit;
                this.$emit('toggleEdit', this.canEdit);
            },
            addOrder() {
                this.$emit('addOrder', this.form);
            },
            async editOrder() {
                if(this.canEdit) {
                    await this.$emit('editOrder', this.form);
                    //this.changeStatus('3');
                } else {
                    this.changeStatus('3');
                }
            },
            changeQuan(quantity, product_id, option) {
                let product = this.products.find(p => parseInt(p.product_id, 10) ===  parseInt(product_id, 10) && p.option === option);
                if(product) {
                    product.quantity = quantity;
                }
            },
            removeProduct(product_id, option) {
                let index = this.products.findIndex(p => parseInt(p.product_id, 10) ===  parseInt(product_id, 10) && p.option === option);
                this.products.splice(index,1)
            },
            createProductSlider() {
                setTimeout(() => {
                    this.productSlider = new Swiper(".products.swiper", {
                        slidesPerView: 'auto',
                        freeMode: true,
                        speed: 100,
                    });
                }, 400);
            },
            mask(value) {
                setTimeout(() => {
                    this.$refs.telephone.setAttribute("maxlength", '14');
                    value = value.replace(new RegExp("[^0-9]+", "g"), "");
                    value = value.replace('380','');
                    if(value[0] === '0') {
                        value = value.substr(1);
                    }
                    let val = '';
                    const x = value.match(/([0-9+]{0,2})([0-9+]{0,3})([0-9+]{0,2})([0-9+]{0,2})/);
                    val = (x[1] ? '(' + x[1] :'') + (x[2].length?') '+x[2]:'') + (x[3] ? '-' + x[3] : '') + (x[4] ? '-' + x[4] : '');
                    this.form.telephone = val;
                }, 30);
            },
            openSelectModal() {
                this.emitter.emit('openSelectModal', this.timeList);
            },
        },
        mounted () {
            this.createProductSlider();
            //this.mask(this.$refs.telephone.value);
            if(this.data.type === 'add') {
                this.canEdit = true;
            }
        }
    }
</script>

<style lang="scss" scoped>
.edit{
    .order_content{
        display: flex;
        flex-direction: column;
        .fields{
            order: 1;
        }
        .products{
            order: 0;
        }
        .buttons{
            order: 2;
        }
    }
}
.order_content{
    border-radius: 8px;
    border-top: 6px solid $yellowColor;
    padding: 0 2em 2em;
    background-color: $darkGrey;
}
.fields{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    .left_block,.right_block{
        display: flex;
        justify-content: space-between;
        align-items: end;
        flex-wrap: wrap;
        width: 48.5%;
    }
}
.input{
    margin-top: 2em;
    &.telephone,&.name{
        width: 49%;
    }
    &.street{
        width: 62%;
    }
    &.house{
        width: 17%;
    }
    &.entrance{
        width: 17%;
    }
    &.payment_method{
        width: 32%;
        &.full{
            width: 48%;
        }
    }
    &.delivery_time{
        width: 32%;
    }
    &.persons{
        width: 32%;
    }
    &.comment{
        width: 100%;
    }
    .label{
        display: block;
        color: #a5a5a5;
        font-size: 0.913em;
        margin-bottom: 0.4em;
    }
    input,textarea{
        color: white;
        padding: 0.813em;
        font-size: 0.913em;
        background-color: $buttonColor;
        &:placeholder{
            color: white;
        }
        &.full_border{
            border-radius: 4px;
        }
        &.left_border{
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
        &.right_border{
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
    textarea{
        height: 2.875em;
        resize: none;
        overflow: hidden;
    }
    .input_payment_cont{
        position: relative;
    }
    .payment_methods{
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: $darkGrey;
        border-radius: 4px;
        z-index: 99999;
        transition: all 400ms ease-in-out;
        &.show-enter-active, &.show-leave-active{
            opacity: 0;
            visibility: hidden;
        }
        .method{
            position: relative;
            padding: 0.613em 0.813em;
            font-size: 0.913em;
            border-bottom: 1px solid #3c3d41;
            cursor: pointer;
            &:last-child{
                border: none;
            }
            input{
                position: absolute;
                z-index: -9999999;
                opacity: 0;
                visibility: hidden;
            }
            label{
                color: white;
                font-weight: 400;
            }
        }
    }
    .plus{
        position: absolute;
        top: 50%;
        right: 0.5em;
        transform: translateY(-50%);
        width: 1.7em;
        height: 1.7em;
        border-radius: 3px;
        background-color: #212121;
        &:after{
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%,-50%);
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 50%;
            filter: brightness(0) invert(1);
            background-image: url('~@/assets/svg/plus.svg');
        }
    }
    .input_tel_cont{
        position: relative;
        input{
            padding: 0.813em 0 0.813em 3.6em;
            &:focus{
                +.placeholder{
                    span{
                        opacity: 0;
                    }
                }
            }
        }
        .placeholder{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: baseline;
            font-weight: 400;
            color: white;
            font-size: 0.913em;
            text-align: left;
            padding: 0.813em;
            line-height: 1.3;
            transition: all 400ms ease-in-out;
            z-index: 99;
            pointer-events: none;
            span{
                transition: all 400ms ease-in-out;
                color: #979797;
                font-weight: 500;
                font-size: 1.063em;
                padding-left: 0.3em;
            }
        }
    }
}
.products{
    display: flex;
    margin-top: 2em;
    .product{
        width: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #313234;
        border-radius: 9px;
        background-color: #1d1d1d;
        margin-right: 10px;
        overflow: hidden;
        height: auto;
        transition: all 300ms linear;
        &.list-enter-active, &.list-leave-active{
            opacity: 0;
        }
        .image{
            width: 3.5em;
            height: 3.5em;
        }
        .description{
            color: white;
            line-height: 1.4;
            padding: 0 0.75em;
            span{
                display: block;
                font-size: 0.813em;
                white-space: nowrap;
            }
            .bottom_block{
                display: flex;
                .option{
                    padding-right: 0.5em;
                }
                .price{
                    padding-right: 0.5em;
                }
                .quan{
                    color: $yellowColor;
                }
            }
        }
    }
}
.buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2em -0.222em 0;
    .left_block,.right_block{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .icon{
        width: 1.5em;
        height: 1.5em;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: auto;
    }
    .to_archive{
        display: flex;
        align-items: center;
        &:hover{
            .icon{
                filter: none;
            }
        }
        .icon{
            background-image: url('~@/assets/svg/archive.svg');
            background-size: 75%;
        }
        .order_id{
            margin-left: 1em;
            color: white;
        }
    }
    .total{
        font-weight: 500;
        margin-right: 0.5em;
        span{
            font-size: 1.125em;
        }
        .title{
            color: #5f6068;
        }
        .value{
            color: white;
        }
    }
    .button{
        border-radius: 4px;
        padding: 0.4em 1.250em;
        background-color: $buttonColor;
        margin: 0 0.222em;
        &.call{
            background-color: $yellowColor;
            // &:hover{
            //     span{
            //         color: black;
            //     }
            // }
            span{
                font-weight: 600;
            }
        }
        &.cancel{
            background-color: $redColor;
            span{
                color: white;
            }
        }
        &.add_product{
            span{
                color: white;
            }
        }
        &.success{
            background-color: $greenColor;
            .icon{
                background-image: url('~@/assets/svg/check.svg');
                filter: invert(1);
            }
        }
        span{
            font-weight: 500;
        }
    }
}
</style>
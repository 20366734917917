<template>
    <div class="page_content admin" ref="page">
        <div class="orders">
            <div class="tables">
                <div class="table new_orders">
                    <div class="table_header">
                        <span>
                            Новые заказы
                        </span>
                        <span>
                            {{data.orders.new_orders.length}}
                        </span>
                    </div>
                    <!-- <RecycleScroller
                        v-if="ordersStatus"
                        class="scroller table_content"
                        :items="data.orders"
                        :item-size="70"
                        key-field="order_id"
                        v-slot="{ item }"
                    > -->
                        <div class="table_content">
                            <div class="row" 
                                :class="[{disabled: item.disabled}, 'order_'+item.order_id, {active : item.order_status_id == 1}]" 
                                v-for="item in data.orders.new_orders" 
                                :key="item.order_id"
                                @click="!item.disabled ? openOrder('new_orders', item.order_id) : openOrder('new_orders', item.order_id)"
                                >
                                <div class="cell order_id">
                                    <div class="icon"></div>
                                    <span class="text">{{item.order_id}}</span>
                                </div>
                                <div class="cell time">
                                    <div class="icon"></div>
                                    <span class="text">{{item.date_added}}</span>
                                </div>
                                <div class="cell telephone">
                                    <div class="icon"></div>
                                    <span class="text">{{item.telephone}}</span>
                                </div>
                                <div class="cell order_button"
                                    :class="[item.order_status_id == 1 ? 'check' : 'eye']"
                                >
                                </div>
                            </div>
                        </div>
                    <!-- </RecycleScroller> -->
                </div>
                <div class="table working_orders">
                    <div class="table_header">
                        <span>
                            В работе
                        </span>
                        <span>
                            {{data.orders.working_orders.length}}
                        </span>
                    </div>
                    <!-- <RecycleScroller
                        v-if="ordersStatus"
                        class="scroller table_content"
                        :items="data.orders"
                        :item-size="70"
                        key-field="order_id"
                        v-slot="{ item }"
                    > -->
                        <div class="table_content">
                            <div class="row" 
                                :class="['order_'+item.order_id]" 
                                v-for="item in data.orders.working_orders" 
                                :key="item.order_id"
                                @click="!item.disabled ? openOrder('working_orders', item.order_id) : ''"
                            >
                                <div class="cell order_id">
                                    <div class="icon"></div>
                                    <span class="text">{{item.order_id}}</span>
                                </div>
                                <div class="cell time">
                                    <div class="icon"></div>
                                    <span class="text">{{item.date_added}}</span>
                                </div>
                                <div class="cell telephone">
                                    <div class="icon"></div>
                                    <span class="text">{{item.telephone}}</span>
                                </div>
                                <div class="cell order_button"
                                    :class="[item.order_status_id == 3 ? 'kitchen disabled' : 'courier']"
                                    @click="parseInt(item.order_status_id, 10) < 4 ? changeStatus({status: 4, orderId: item.order_id, telephone: item.telephone}) : ''"
                                >
                                </div>
                            </div>
                        </div>
                    <!-- </RecycleScroller> -->
                </div>
            </div>
        </div>
        <OrderContainer @openMap="openMap($event)" />
        <Map :coordinates="coordinates" />
    </div>
</template>

<script>
    import ReconnectingWebSocket from 'reconnecting-websocket';
    import * as Orders from "@/api/Orders";

    import OrderContainer from "@/components/Container/OrderContainer";
    import Map from "@/components/Map";
    export default {
        name: 'Admin',
        components: {
            OrderContainer,
            Map,
        },
        setup () {
            
            return {}
        },
        data() {
            return{
                ordersStatus: 0,
                data: {
                    orders: {
                        'new_orders': [],
                        'working_orders': [],
                    },
                    statuses: [],
                    couriers: [],
                    payment_methods: [],
                },
                coordinates: null,
                counter: 0,
                mapOpened: false,
                openedOrder: null,
                audio: null,
            }
        },
        async created() {
            this.emitter.on('openMapHandler', (orderId) => {
                this.openMap(orderId);
            })
            await this.getCurrentOrders();
            this.ordersStatus = 1;
            const rws = new ReconnectingWebSocket(this.webSocketLink, null, { debug: false, reconnectInterval: 1000 });
            rws.onmessage = (event) =>  {
                const {type,data} = JSON.parse(event.data);
                if(type === 'changeStatus') {
                    const currentStatus = this.data.statuses.find((item) => parseInt(item.order_status_id) === parseInt(data.status));
                    if(parseInt(data.status) === 1) {
                        const currentOrder = this.data.orders.new_orders.findIndex((item) => parseInt(item.order_id) === parseInt(data.orderId));
                        this.data.orders.new_orders[currentOrder].disabled = false;
                        this.data.orders.new_orders[currentOrder].order_status = currentStatus.name;
                        this.data.orders.new_orders[currentOrder].order_status_id = currentStatus.order_status_id;
                    }
                    if(parseInt(data.status) === 2) {
                        const currentOrder = this.data.orders.new_orders.findIndex((item) => parseInt(item.order_id) === parseInt(data.orderId));
                        this.data.orders.new_orders[currentOrder].disabled = true;
                        this.data.orders.new_orders[currentOrder].order_status = currentStatus.name;
                        this.data.orders.new_orders[currentOrder].order_status_id = currentStatus.order_status_id;
                    }
                    if(parseInt(data.status) === 3) {
                        const currentOrder = this.data.orders.new_orders.findIndex((item) => parseInt(item.order_id) === parseInt(data.orderId));
                        let order = this.data.orders.new_orders[currentOrder];
                        this.data.orders.new_orders.splice(currentOrder,1);
                        order.order_status = currentStatus.name;
                        order.order_status_id = currentStatus.order_status_id;
                        order.disabled = false;
                        this.data.orders.working_orders.unshift(order);
                        this.setActiveOrder(order.order_id);
                    }
                    if(parseInt(data.status) > 3) {
                        const currentOrder = this.data.orders.working_orders.findIndex((item) => parseInt(item.order_id, 10) === parseInt(data.orderId, 10));
                        if(parseInt(data.status) === 5) {
                            this.data.orders.working_orders.splice(currentOrder,1);
                            return true;
                        }
                        this.data.orders.working_orders[currentOrder].disabled = false;
                        this.data.orders.working_orders[currentOrder].order_status = currentStatus.name;
                        this.data.orders.working_orders[currentOrder].order_status_id = currentStatus.order_status_id;
                    }
                }
                if(type === 'createOrder') {
                    const currentStatus = this.data.statuses.find((item) => parseInt(item.order_status_id, 10) === parseInt(data.order_status_id, 10));
                    let newOrder = data;
                    newOrder.telephone = data.telephone.replace('(', '(0');
                    newOrder.order_status = currentStatus.name;
                    if(parseInt(data.order_status_id, 10) === 1) {
                        this.data.orders.new_orders.unshift(newOrder);
                    } else {
                        this.data.orders.working_orders.unshift(newOrder);
                    }
                    this.audio.play();
                    //this.setActiveOrder(data.order_id);
                }
                if(type === 'changeLocation') {
                    this.coordinates = data;
                    if(this.mapOpened) {
                        this.emitter.emit('changeMarkerPosition', data);
                    }
                }
            };
        },
        mounted() {
            this.audio = new Audio();
            this.audio.src = 'https://gorilla-admin-test.sigma-studio.pp.ua/ringtone.mp3';
        },
        methods: {
            async getCurrentOrders() {
                this.data = await Orders.getCurrentOrders();
            },
            filterUsers(users) {
                let sortable = [];
                for (let user in users) {
                    sortable.push([user, users[user]])
                }
                sortable.sort(function(a, b) {
                    let d1 = Object.values(a[1]);
                    d1.splice(0,1);
                    let d2 = Object.values(b[1]);
                    d2.splice(0,1);
                    return d2.filter(item => item === "1").length - d1.filter(item => item === "1").length;
                });
                const vals = [...new Map(sortable).values()]
                return vals;
            },
            remove() {
                
            },
            // openSelectModal(type, orderId, current) {
            //     let modalData = [];
            //     if(type === 'status') {
            //         modalData = this.data.statuses;
            //     }
            //     this.emitter.emit('openSelectModal', {'list': modalData, type, orderId, current})
            // },
            setActiveOrder(orderId) {
                setTimeout(() => {
                    let newOrderElement = document.querySelector(".order_"+orderId);
                    newOrderElement.classList.add('active');
                    setTimeout(() => {
                        newOrderElement.classList.remove('active'); 
                    }, 5000);
                }, 100);
            },
            changeStatus(data) {
                Orders.editOrderStatus(data);
                return;
            },
            openMap(orderId) {
                this.emitter.emit('openMap', {couriers: this.data.couriers, orderId});
                this.mapOpened = true;
            },
            openOrder(type,orderId) {
                // if(event.target.classList.contains('disabled')) {
                //     return;
                // }
                let orderData = this.data.orders[type].find(order => parseInt(order.order_id) === parseInt(orderId));
                orderData.type = 'edit';
                if(orderData.order_status_id > 3){
                    orderData.type = 'show';
                }
                this.emitter.emit('openOrder', orderData);
            },
        }
    }
</script>

<style lang="scss" scoped>
    .page_content{
        position: relative;
        overflow: hidden;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
        .tables{
            display: flex;
            justify-content: space-between;
            //margin-top: 1.7em;
        }
        .table{
            width: 49%;
            &.working_orders{
                .table_header{
                    background-color: $yellowColor;
                    span{
                        color: black;
                        font-weight: 600;
                    }
                }
            }
        }
        .table_header{
            background-color: $buttonColor;
            padding: 0.938em 1.1em;
            border-radius: 13px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 1.4;
            span{
                text-transform: uppercase;
                color: white;
                font-size: 0.913em;
                font-weight: 500;
                letter-spacing: 0.03em;
            }
        }
        .table_content{
            min-height: 33em;
            max-height: 33em;
            overflow: hidden;
            overflow-y: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
            .row{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 0.9em;
                padding: 0.938em;
                border-radius: 8px;
                cursor: pointer;
                transition: all 400ms ease-in-out;
                position: relative;
                background-color: #262626;
                &:hover{
                    background-color: $darkGrey;
                }
                &.disabled{
                    // cursor: default;
                    // pointer-events: none;
                    .cell{
                        &.order_button{
                            &.to_work{
                                &:after{
                                    background-image: url('~@/assets/svg/eye.svg');
                                    background-size: 40%;
                                }
                            }
                        }
                    }
                }
                &.active{
                    .cell{
                        &.order_button{
                            &.check{
                                background-color: $greenColor;
                                &:after{
                                    filter: invert(1);
                                }
                            }
                        }
                    }
                }
                .cell{
                    display: flex;
                    align-items: center;
                    transition: all 400ms ease-in-out;
                    &.order_id{
                        .icon{
                            background-image: url('~@/assets/svg/Order/cash.svg');
                        }
                    }
                    &.time{
                        .icon{
                            background-image: url('~@/assets/svg/Order/time.svg');
                        }
                    }
                    &.telephone{
                        .icon{
                            background-image: url('~@/assets/svg/Order/telephone.svg');
                            margin-right: 0.3em;
                        }
                    }
                    &.order_button{
                        padding: 0.9em 1.6em;
                        background-color: $buttonColor;
                        border-radius: 4px;
                        background-repeat: no-repeat;
                        background-position: 50%;
                        background-size: cover;
                        position: relative;
                        &:hover{
                            background-color: $greenColor;
                        }
                        &:after{
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-repeat: no-repeat;
                            background-position: 50%;
                            background-size: 30%;
                            transition: all 400ms ease-in-out;
                        }
                        &.check{
                            &:after{
                                background-image: url('~@/assets/svg/check.svg');
                            }
                        }
                        &.eye{
                            &:after{
                                background-image: url('~@/assets/svg/eye.svg');
                                background-size: 40%;
                            }
                        }
                        &.kitchen{
                            &:after{
                                background-image: url('~@/assets/svg/Statuses/kitchen.svg');
                                background-size: 40%;
                            }
                        }
                        &.courier{
                            background-color: $yellowColor;
                            &:after{
                                background-image: url('~@/assets/svg/Statuses/courier.svg');
                                background-size: 40%;
                                filter: invert(1);
                                background-position: 50% 35%;
                            }
                        }
                    }
                    .icon{
                        width: 1.25em;
                        height: 1.25em;
                        margin-right: 0.6em;
                        background-repeat: no-repeat;
                        background-position: 50%;
                        background-size: cover;
                    }
                    .text{
                        color: white;
                        font-weight: 400;
                        font-size: 0.813em;
                        letter-spacing: 0.03em;
                    }
                }
            }
        }
        .table_content::-webkit-scrollbar {
            width: 4px;
            height: 3px;
            background-color: white;
        }
        .table_content::-webkit-scrollbar-button {
            width: 0;
            height: 0;
        }
        .table_content::-webkit-scrollbar-thumb {
            background: $yellowColor;
            border: none;
        }
    }
</style>
<template>
    <div class="quantity">
        <div class="minus quan_button" @click="change('minus')"></div>
        <div class="plus quan_button" @click="change('plus')"></div>
    </div>
</template>

<script>
    export default {
        props: {
            quantity: {
                type: Number,
                default: 1,
            },
            canEdit: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return{
                quan: this.quantity,
            }
        },
        methods: {
            change(action) {
                if(!this.canEdit) return;
                if(action === 'minus') {
                    if(this.quan === 1){
                        this.$emit('removeProduct');
                        return true;
                    }
                    this.quan--;
                }
                if(action === 'plus') {
                    this.quan++;
                }
                this.$emit('changeQuan', this.quan);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .quantity{
        display: flex;
        height: 100%;
        margin-left: 0.5em;
        .quan_button{
            width: 2.6em;
            height: 100%;
            position: relative;
            border-left: 1px solid #313234;
            cursor: pointer;
        }
        .minus{
            &:after{
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 0.813em;
                height: 2px;
                background-color: white;
            }
        }
        .plus{
            &:after{
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 0.813em;
                height: 2px;
                background-color: white;
            }
            &:before{
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 2px;
                height: 0.813em;
                background-color: white;
            }
        }
    }
</style>
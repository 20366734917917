<template>
    <div class="right_block">
        <div class="logo" @click="toggleFullScreen">
            <img src="@/assets/svg/logo.svg" alt="Logo">
        </div>
        <div class="block card_block">
            <div class="icon">
                <img src="@/assets/svg/card.svg" alt="Card">
            </div>
            <div class="text">
                <span class="total">
                    ₴ 73.352
                </span>
                <span class="average">
                    Средний: 562
                </span>
            </div>
        </div>
        <div class="block employees_block">
            <!-- <span class="title">
                Сотрудники
            </span> -->
            <ul>
                <li class="employee">
                    <div class="image">
                        <img src="@/assets/images/employees/2.jpg" alt="">
                    </div>
                    <div class="text">
                        <span class="name">
                            Дмитрий
                        </span>
                        <span class="post">
                            доставка
                        </span>
                    </div>
                </li>
                <li class="employee">
                    <div class="image">
                        <img src="@/assets/images/employees/3.jpg" alt="">
                    </div>
                    <div class="text">
                        <span class="name">
                            Виктория
                        </span>
                        <span class="post">
                            оператор
                        </span>
                    </div>
                </li>
                <li class="employee">
                    <div class="image">
                        <img src="@/assets/images/employees/4.jpg" alt="">
                    </div>
                    <div class="text">
                        <span class="name">
                            Маргарита
                        </span>
                        <span class="post">
                            оператор
                        </span>
                    </div>
                </li>
                <li class="employee">
                    <div class="image">
                        <img src="@/assets/images/employees/1.jpg" alt="">
                    </div>
                    <div class="text">
                        <span class="name">
                            Ирина
                        </span>
                        <span class="post">
                            оператор
                        </span>
                    </div>
                </li>
                <li class="employee">
                    <div class="image">
                        <img src="@/assets/images/employees/2.jpg" alt="">
                    </div>
                    <div class="text">
                        <span class="name">
                            Ирина
                        </span>
                        <span class="post">
                            оператор
                        </span>
                    </div>
                </li>
                <li class="employee">
                    <div class="image">
                        <img src="@/assets/images/employees/3.jpg" alt="">
                    </div>
                    <div class="text">
                        <span class="name">
                            Ирина
                        </span>
                        <span class="post">
                            оператор
                        </span>
                    </div>
                </li>
                <li class="employee">
                    <div class="image">
                        <img src="@/assets/images/employees/4.jpg" alt="">
                    </div>
                    <div class="text">
                        <span class="name">
                            Ирина
                        </span>
                        <span class="post">
                            оператор
                        </span>
                    </div>
                </li>
                <li class="employee">
                    <div class="image">
                        <img src="@/assets/images/employees/5.jpg" alt="">
                    </div>
                    <div class="text">
                        <span class="name">
                            Ирина
                        </span>
                        <span class="post">
                            оператор
                        </span>
                    </div>
                </li>
            </ul>
        </div>
        <div class="block map_block">
            <!-- <span class="title">
                Зона доставки
            </span> -->
            <div class="preview_map" id="preview_map" @click="openMap"></div>
        </div>
    </div>
</template>

<script>
import * as Leaflet from 'leaflet';
import 'leaflet/dist/leaflet.css';
export default {
    setup () {
        

        return {}
    },
    data () {
        return{
            placeIcon: require('@/assets/svg/Markers/1.svg'),
        }
    },
    created () {
        setTimeout(() => {
            let previewMap = Leaflet.map('preview_map', { zoomControl: false }).setView([46.6508388,32.5963062], 9);
            Leaflet.tileLayer('https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png', {
                attribution: ''
            }).addTo(previewMap);
            let placeIcon = Leaflet.icon({
                iconUrl: this.placeIcon,
                iconSize:     [40, 40],
                iconAnchor:   [25, 35],
                popupAnchor:  [-3, -76]
            });

            Leaflet.marker([46.6400852,32.593524], {icon: placeIcon}).addTo(previewMap);
        }, 50);
    },
    mounted() {
        setTimeout(() => {
            document.querySelector(".leaflet-bottom.leaflet-right").remove();
        }, 200);
    },
    methods: {
        openMap() {
            // this.emitter.emit('openMap', {couriers: this.data.couriers, orderId});
            // this.mapOpened = true;
        },
        toggleFullScreen() {
            if (!document.fullscreenElement) {
                document.documentElement.requestFullscreen();
            } else {
                if (document.exitFullscreen) {
                document.exitFullscreen();
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .right_block{
        padding: 1.5em;
        background-color: $darkGrey;
        border-radius: 27px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 19%;
        margin-top: 1.2em;
    }
    .logo{
        width: 91%;
        img{
            object-fit: contain;
        }
    }
    .block{
        width: 100%;
    }
    .title{
        font-size: 1em;
        color: white;
        font-weight: 500;
        display: block;
        text-align: left;
        margin-bottom: 0.813em;
    }
    .card_block{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.5em;
        .icon{
            width: 5.5em;
            line-height: 0;
        }
        .text{
            line-height: 1.4;
            span{
                display: block;
                white-space: nowrap;
                font-weight: 500;
                &.total{
                    color: white;
                    font-size: 0.875em;
                }
                &.average{
                    color: #828282;
                    font-size: 0.688em;
                }
            }
        }
    }
    .employees_block{
        ul{
            overflow: hidden;
            max-height: 13.257em;
            overflow-y: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        .employee{
            display: flex;
            align-items: center;
            margin-bottom: 1em;
            .image{
                width: 2.5em;
                height: 2.5em;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 1em;
            }
            .text{
                line-height: 1.2;
                span{
                    display: block;
                    font-weight: 400;
                    &.name{
                        color: white;
                        font-size: 0.875em;
                    }
                    &.post{
                        color: #828282;
                        font-size: 0.75em;
                    }
                }
            }
        }
    }
    .preview_map{
        width: 100%;
        height: 16em;
        overflow: hidden;
        border-radius: 13px;
        background-color: $darkGrey;
        .leaflet-bottom.leaflet-right{
            display: none;
        }
    }
</style>
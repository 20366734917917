<template>
    <div class="page_content admin" ref="page">
        <div class="filter">
            <span class="variable active" @click="selectFilter('today')">
                Сегодня
            </span>
            <span class="variable" @click="selectFilter('week')">
                Неделя
            </span>
            <span class="variable" @click="selectFilter('month')">
                Месяц
            </span>
            <span class="variable">
                Безнал
            </span>
            <span class="variable">
                Наличка
            </span>
            <span class="variable">
                Сотрудник
            </span>
            <span class="variable">
                368
            </span>
        </div>
        <div class="orders">
            <div class="order" v-for="order in orders" :key="order.order_id">
                <div class="top_line">
                    <span class="id">{{order.order_id}}</span>
                    <span class="resource">{{order.resource}}</span>
                    <span class="date">{{order.date}}</span>
                    <span class="time">{{order.time}}</span>
                    <span class="payment_method">{{order.payment_title}}</span>
                    <span class="name">{{order.firstname}}</span>
                    <span class="total">₴ {{order.total}}</span>
                    <div class="button">
                        <div class="icon"></div>
                    </div>
                </div>
                <div class="bottom_line">
                    <div class="products swiper">
                        <div class="swiper-wrapper products">
                            <div class="product swiper-slide" 
                            v-for="product in order.products" 
                            :key="product.product_id">
                                <div class="image">
                                    <img :src="product.image" alt="">
                                </div>
                                <div class="description">
                                    <span class="name">
                                        {{product.name}}
                                    </span>
                                    <div class="bottom_block">
                                        <span class="price">
                                            {{product.price}} грн
                                        </span>
                                        <span class="option" v-if="product.option_value">
                                            {{product.option_value}} см
                                        </span>
                                        <span class="quan">
                                            x {{product.quantity}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="button">
                        <div class="icon"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as Orders from "@/api/Orders";
    import Swiper from 'swiper';
    import 'swiper/swiper.min.css';
export default {
    setup () {
        return {}
    },
    data () {
        return{
            orders: {},
            filter: 'today',
        }
    },
    methods: {
        selectFilter(value) {
            this.filter = value;
            this.getOrdersHistory();
            let variables = document.querySelectorAll('.filter .variable');
            for(let i = 0; i < variables.length; i++) {
                variables[i].classList.remove('active');
            }
            event.target.classList.add('active');
        },
        async getOrdersHistory() {
            let formData = new FormData();
            formData.append('filter_data', this.filter);
            this.orders = await Orders.getOrdersHistory(formData);
            setTimeout(() => {
                new Swiper(".products.swiper", {
                    slidesPerView: 'auto',
                    freeMode: true,
                    speed: 100,
                });
            }, 400);
        }
    },
    async created() {
        await this.getOrdersHistory();
    },
    mounted() {
       //this.createProductSlider();
    }
}
</script>

<style lang="scss" scoped>
    .filter{
        background-color: $buttonColor;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.6em;
        border-radius: 8px;
        span{
            padding: 0.5em 1.2em;
            color: white;
            border-radius: 8px;
            font-weight: 500;
            text-transform: uppercase;
            font-size: 0.875em;
            cursor: pointer;
            transition: all 400ms ease-in-out;
            &.active{
                background-color: $grey;
            }
        }
    }
    .orders{
        min-height: 33em;
        max-height: 33em;
        overflow: hidden;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .button{
        padding: 0.4em 1.250em;
        padding: 1em;
        background-color: $buttonColor;
        .icon{
            width: 1.5em;
            height: 1.5em;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: auto;
        }
    }
    .order{
        color: white;
        background-color: $darkGrey;
        margin-top: 0.9em;
        padding: 1.2em;
        border-radius: 8px;
    }
    .top_line{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .id{
            display: flex;
            align-items: center;
            &:before{
                content: "";
                display: block;
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background-color: $greenColor;
                margin-right: 0.7em;
            }
        }
    }
    .bottom_line{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.5em;
    }
    .products{
        display: flex;
        .product{
            width: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #313234;
            border-radius: 9px;
            background-color: #1d1d1d;
            margin-right: 10px;
            overflow: hidden;
            height: auto;
            transition: all 300ms linear;
            &.list-enter-active, &.list-leave-active{
                opacity: 0;
            }
            .image{
                width: 3.5em;
                height: 3.5em;
            }
            .description{
                color: white;
                line-height: 1.4;
                padding: 0 0.75em;
                span{
                    display: block;
                    font-size: 0.813em;
                    white-space: nowrap;
                }
                .bottom_block{
                    display: flex;
                    .option{
                        padding-right: 0.5em;
                    }
                    .price{
                        padding-right: 0.5em;
                    }
                    .quan{
                        color: $yellowColor;
                    }
                }
            }
        }
}
</style>
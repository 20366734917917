<template>
    <transition name="show">
        <div class="select_modal" v-show="show">
            <div class="content">
                <ul ref="list">
                    <li v-for="(item, index) in list" :key="item" @click="selectValue(item, index)" ref="item">
                        {{item}}
                    </li>
                </ul>
                <div class="buttons">
                    <span class="close" @click="closeModal('clear')">Назад</span>
                    <span class="apply" @click="closeModal('submit')">Ок</span>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        data() {
            return {
                show: false,
                value: '',
                list: [],
                elementList: [],
            }
        },
        methods: {
            closeModal(action) {
                if(action === 'clear') {
                    this.value = '';
                }
                this.show = false;
                this.emitter.emit('selectModalValue', this.value)
            },
            selectValue(value, index) {
                this.value = value;
                for(let i = 0; i < this.elementList.length; i++) {
                    this.elementList[i].classList.remove('active');
                }
                this.elementList[index].classList.add('active');
            }
        },
        created() {
            this.emitter.on('openSelectModal', (list) => {
                this.list = list;
                setTimeout(() => {
                    this.show = true;
                    this.elementList = this.$refs.list.querySelectorAll("li");
                    if(!this.value) {
                        for(let i = 0; i < this.elementList.length; i++) {
                            this.elementList[i].classList.remove('active');
                        }
                        this.value = this.elementList[2].textContent;
                        this.elementList[2].classList.add('active');
                    }
                }, 100);
            }) 
        }
    }
</script>

<style lang="scss" scoped>
    .select_modal{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99999;
        background-color: rgba(0, 0, 0, 0.5);
        transition: all 300ms ease-in-out;
        &.show-enter-active, &.show-leave-active{
            opacity: 0;
        }
    }
    .content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding: 3em 1em;
        background-color: $darkGrey;
        border-radius: 11px;
        min-width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    ul{
        max-height: 12.5em;
        overflow: hidden;
        overflow-y: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    li{
        position: relative;
        font-size: 1.4em;
        letter-spacing: 0.03em;
        text-align: center;
        color: #979797;
        font-weight: 500;
        margin-bottom: 0.7em;
        will-change: transform;
        backface-visibility: hidden;
        transition: all 400ms ease-in-out;
        cursor: pointer;
        &.active{
            color: $greenColor;
            transform: scale(1.2);
        }
    }
    .buttons{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0.5em auto -1.4em;
        span{
            font-size: 1.3em;
            padding: 1em;
            letter-spacing: 0.03em;
            font-weight: 600;
            cursor: pointer;
            &.apply{
                color: $greenColor;
                text-transform: uppercase;
            }
            &.close{
                color: #979797;
            }
        }
    }
</style>
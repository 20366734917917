import { createRouter,createWebHistory} from 'vue-router';
import Home from "@/views/Home";
import History from "@/views/History";
import Login from "@/views/Login";
import {store} from '@/store';
const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: { 
            requiresAuth: true
        }
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/history",
        name: "History",
        component: History,
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes
})
router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
            next('/login') 
        } else {
            next() 
    }
})
export {router}
<template>
    <Loader ref="Loader" />
    <div class="page">
        <LeftBlock />
        <div class="content">
            <Header />
            <router-view/>
        </div>
        <RightBlock />
    </div>
    <SnackBar />
    <SelectModal />
</template>

<script>
    //import axios from "axios";
    import Loader from "@/components/Loader/Loader"
    import Header from "@/components/Common/Header";
    import LeftBlock from "@/components/Common/LeftBlock";
    import RightBlock from "@/components/Common/RightBlock";
    import SnackBar from "@/components/SnackBar";
    import SelectModal from "@/components/SelectModal";
    //import {fireDB} from "@/plugins/firebase";
export default {
    name: 'App',
    components: {
        Loader,
        Header,
        LeftBlock,
        RightBlock,
        SnackBar,
        SelectModal
    },
    data () {
        return {
            
        }
    },
    created () {
        //console.log(this.$http)
        this.$http.interceptors.response.use(undefined, function (err) {
            console.log('t2')
            return new Promise(function () {
                console.log('t1')
                if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
                    this.$store.dispatch("logout")
                }
                throw err;
            });
        });
        this.$store.dispatch("storeData");
    },
    mounted() {
        // let fireDbConfig = {
        //     apiKey: "AIzaSyAX7himNJpuPxhhMa-6IotYEv9o9h59j9A",
        //     authDomain: "tuda-suda-54437.firebaseapp.com",
        //     projectId: "tuda-suda-54437",
        //     storageBucket: "tuda-suda-54437.appspot.com",
        //     messagingSenderId: "932579044570",
        //     appId: "1:932579044570:web:bb03d25fab51c14ea489e3",
        // };
        this.$refs.Loader.start();
        // window.firebase.initializeApp(fireDbConfig);
        // fireDB.config(fireDbConfig);   
        // fireDB.init();
        // fireDB.requestPermission(function() {
        //     if(fireDB.isTokenSentToServer()) {
        //         let formData = new FormData();
        //         formData.append('api_key', fireDB.token)
        //         axios.post('https://api-tuda-suda.sigma-studio.pp.ua/browser-push/send.php?get', formData);
        //     }
        // });
    }
}
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
</style>

<style lang="scss" scoped>
    .page{
        display: flex;
        justify-content: space-between;
    }
    .content{
        padding: 0 1.7em;
        width: 74.3%;
    }
</style>
import axios from "axios";

const apiLink = 'https://api-tuda-suda.sigma-studio.pp.ua/';
const headers = {
    "Content-Type": 'multipart/form-data',
}
export async function getCurrentOrders() {
    try {
        const response = await axios.get(apiLink+'index.php?route=api/admin/order');
        const data = response.data;
        return data;
    } catch (errors) {
        console.error(errors);
    }
}

export async function getOrdersHistory(filter) {
    try {
        const response = await axios.post(apiLink+'index.php?route=api/admin/orders_history', filter);
        const data = response.data;
        return data;
    } catch (errors) {
        console.error(errors);
    }
}

export async function editOrderStatus(form) {
    try {
        let formData = new FormData();
        for (let key in form) {
            formData.append(key, form[key]);
        }
        const response = await axios.post(apiLink+'index.php?route=api/admin/order/editOrderStatus', formData, headers);
        const data = response.data;
        return data;
    } catch (errors) {
        console.error(errors);
    }
}

export async function addOrder(form) {
    try {
        const response = await axios.post(apiLink+'index.php?route=checkout/order/create_order',form);
        const data = response.data;
        return data;
    } catch (errors) {
        console.error(errors);
    }
}

export async function editOrder(form) {
    try {
        const response = await axios.post(apiLink+'index.php?route=api/admin/order/editOrder',form);
        const data = response.data;
        return data;
    } catch (errors) {
        console.error(errors);
    }
}

export async function setCourier(form) {
    try {
        const response = await axios.post(apiLink+'index.php?route=api/admin/order/setCourier',form);
        const data = response.data;
        return data;
    } catch (errors) {
        console.error(errors);
    }
}
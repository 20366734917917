import { createStore } from 'vuex';
import {getData} from '@/api/Data.js';
import axios from 'axios';
const apiLink = 'https://api-tuda-suda.sigma-studio.pp.ua/';
export const store = createStore({
    state: {
        status: '',
        token: localStorage.getItem('token') || '',
        user : {},
        catalog: {
            products: [],
            categories: [],
            status: false,
        },
    },
    mutations: {
        auth_request(state){
            state.status = 'loading'
        },
        auth_success(state, token, user){
            state.status = 'success'
            state.token = token
            state.user = user
        },
        auth_error(state){
            state.status = 'error'
        },
        logout(state){
            state.status = ''
            state.token = ''
        },
        SET_DATA(state, data) {
            state.catalog.products = data.products;
            state.catalog.categories = data.categories;
            state.catalog.status = true;
        },
    },
    actions: {
        login({commit}, user){
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({url: apiLink+'index.php?route=api/admin/login', data: user, method: 'POST' })
                .then(resp => {
                    const token = resp.data.token
                    const user = resp.data.user
                    localStorage.setItem('token', token)
                    axios.defaults.headers.common['Authorization'] = token
                    commit('auth_success', token, user)
                    resolve(resp)
                })
                .catch(err => {
                    commit('auth_error')
                    localStorage.removeItem('token')
                    reject(err)
                })
            })
        },
        logout({commit}){
            return new Promise((resolve) => {
                commit('logout')
                localStorage.removeItem('token')
                delete axios.defaults.headers.common['Authorization']
                resolve()
            })
        },
        async storeData({commit}){
            await getData().then((result) => {
                console.log(result)
                commit('SET_DATA', result);
            })
        },
    },
    getters : {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
    }
})
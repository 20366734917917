<template>
    <div class="product_list swiper">
        <transition-group name="list" tag="div" class="swiper-wrapper">
            <ProductItem v-for="product in products" :key="product.product_id" :data='product' ref="product" />
        </transition-group>
    </div>
</template>

<script>
    import Swiper from 'swiper';
    import 'swiper/swiper.min.css';
    import ProductItem from '@/components/Products/ProductItem'
    export default {
        props: {
            'products': {

            },
        },
        data() {
            return{
                productSlider: null,
            }
        },
        components: {
            ProductItem,
        },
        methods: {
            updateSlider() {
                this.productSlider.updateProgress();
                this.productSlider.update();
                this.productSlider.updateSlides();
                this.productSlider.updateSize();
                this.productSlider.slideTo(0, 500);
            },
        },
        mounted() {
            setTimeout(() => {
                this.productSlider = new Swiper(".product_list.swiper", {
                    freeMode: true,
                    slidesPerView: 'auto',
                    spaceBetween: 14,
                    speed: 300,
                    momentum: false,
                    momentumBounce: false,
                    //autoHeight: true,
                });
            }, 200);
        }
    }
</script>

<style lang="scss" scoped>
    .product_list{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        opacity: 1;
    }
</style>
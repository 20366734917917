<template>
    <div>
        <div class="form-group">
            <label for="input-username">Логин</label>
            <div class="input-group">
                <input type="text" name="username" v-model="form.username" placeholder="Логин" id="input-username" class="form-control">
            </div>
        </div>
        <div class="form-group">
            <label for="input-password">Пароль</label>
            <div class="input-group">
                <input type="password" name="password" v-model="form.password" placeholder="Пароль" id="input-password" class="form-control">
            </div>
        </div>
        <div class="text-right">
            <button type="submit" class="btn btn-primary" @click="login()">Войти</button>
        </div>
    </div>
</template>

<script>
//import {store} from '@/store/login.js';
export default {
    setup () {
        

        return {}
    },
    data() {
        return{
            form: {
                username: '',
                password: ''
            }
        }
    },
    methods: {
        login() {
            let formData = new FormData();
            for (let key in this.form) {
                formData.append(key, this.form[key]);
            }
            this.$store.dispatch('login', formData)
            .then(() => this.$router.push('/'))
            .catch(err => console.log(err))
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
<template>
    <div class="item_container swiper-slide">
        <div class="item">
            <Options v-if="data.options" :options="data.options.product_option_value" @changeOption="changeOption($event)" />
            <div class="image" @click="addToCart">
                <img :src="data.image+''" :alt="data.name">
            </div>
            <div class="description">
                <span class="name">{{data.name}}</span>
                <span class="consist">{{data.description}}</span>
                <div class="buttons">
                    <span class="price">{{data.currency}} {{price}}</span>
                    <div class="add_to_cart" @click="addToCart"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Options from "@/components/Products/Options"
    export default {
        props: [
            'data',
        ],
        data() {
            return{
                price: 0,
                quantity: 1,
                option: false,
                image: '',
                optionValue: '',
            }
        },
        components: {
            Options,
        },
        created() {
            this.price = this.data.price;
            if(this.data.options) {
                this.price = this.data.options.product_option_value[0].price;
                this.option = this.data.options.product_option_id + '-' + this.data.options.product_option_value[0].product_option_value_id;
                this.optionValue = this.data.options.product_option_value[0].name;
            }
        },
        methods: {
            changeOption(option_id) {
                let index = this.data.options.product_option_value.findIndex( currentValue => parseInt(`${currentValue.product_option_value_id}`) === parseInt(`${option_id}`));
                this.price = this.data.options.product_option_value[index].price;
                this.option = this.data.options.product_option_id + '-' + this.data.options.product_option_value[index].product_option_value_id;
                this.optionValue = this.data.options.product_option_value[index].name;
            },
            addToCart() {
                let addingProduct = {
                    image: this.data.image,
                    name: this.data.name,
                    product_id: this.data.product_id,
                    option_value: this.optionValue,
                    option: this.option,
                    price: this.price,
                    quantity: 1,
                }
                this.emitter.emit('addCartProduct', addingProduct);
                this.emitter.emit('updateSlider');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .item_container{
        width: 19%;
        height: auto;
        display: block;
        border-radius: 23px;
        margin-right: 14px;
        color: white;
        overflow: hidden;
        transition: box-shadow 400ms ease-in-out, opacity 400ms ease-in-out;
        opacity: 1;
        cursor: pointer;
        &:hover{
            color: white !important;
        }
        &.list-enter-active, &.list-leave-active{
            opacity: 0;
        }
        .item{
            position: relative;
            height: 100%;
        }
        .options{
            position: absolute;
            top: 0.8em;
            left: 0.7em;
            background-color: transparent;
            flex-direction: column;
            transform: none;
            border-radius: unset;
            padding: 0;
            ::v-deep{
                .option{
                    border-radius: 3px;
                    background-color: black;
                    width: auto;
                    margin-bottom: 0.3em;
                    &.active{
                        .text{
                            color: $greenColor;
                        }
                    }
                    .text{
                        font-size: 0.75em;
                        padding: 0.3em 0.6em;
                    }
                }
            }
        }
        .image{
            line-height: 0;
            height: 8.9em;
            display: block;
        }
        .description{
            display: flex;
            flex-direction: column;
            padding: 0.6em 0.9em 0.8em;
            background-color: #111111;
            height: calc(100% - 8.9em);
        }
        .name{
            font-weight: 500;
            line-height: 1.2;
        }
        .consist{
            display: block;
            font-size: 0.625em;
            font-weight: 300;
            line-height: 1.3;
            margin: 0.5em 0 1em;
            letter-spacing: 0.03em;
            transition: all 500ms ease-in-out;
        }
        .buttons{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: auto;
        }
        .add_to_cart{
            width: 2em;
            height: 2em;
            margin-top: -0.1em;
            background-color: #4c4d51;
            position: relative;
            border-radius: 50%;
            &:after{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: 50%;
                background-size: 39%;
                background-image: url('~@/assets/svg/plus.svg');
                filter: brightness(0) invert(1);
            }
        }
        .price{
            font-weight: 500;
            font-size: 1.188em;
        }
    }
</style>
<template>
    <div class="filter swiper">
        <div class="sort swiper-wrapper">
            <div class="variable swiper-slide" ref="variable" v-for="(category, index) in categories" @click="filter(category.category_id, index)" :key="category.category_id">
                <div class="icon">
                    <img :src="category.image" alt="">
                </div>
                <span>
                    {{category.name}}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import Swiper from 'swiper';
    import 'swiper/swiper.min.css';
    export default {
        props: [
            'categories',
        ],
        data(){
            return{
                category_id: 0,
            }
        },
        methods: {
            filter(category_id, index) {
                if(this.category_id === category_id) {
                    this.category_id = 0;
                    this.clearActive();
                } else {
                    this.category_id = category_id;
                    for(let i = 0; i < document.querySelectorAll('.filter .variable').length; i++) {
                        document.querySelectorAll('.filter .variable')[i].classList.remove('active');
                    }
                    document.querySelectorAll('.filter .variable')[index].classList.add('active')
                }
                this.$emit('filterProducts', {category_id: this.category_id});
            },
            clearActive() {
                let variables = document.querySelectorAll('.filter .variable');
                for(let i = 0; i < variables.length; i++) {
                    variables[i].classList.remove('active');
                }
            }
        },
        mounted() {
            setTimeout(() => {
                document.querySelectorAll('.filter .variable')[0].classList.add('active');
                this.category_id = this.categories[0].category_id;
                this.$emit('filterProducts', {category_id: this.category_id});
                setTimeout(() => {
                    new Swiper(".filter.swiper", {
                        slidesPerView: 'auto',
                        freeMode: true,
                    });
                }, 400);
            }, 50);
        }
    }
</script>

<style lang="scss" scoped>
    .filter.swiper{
        height: fit-content;
        //margin-top: 1.7em;
    }
    .sort{
        display: flex;
        .variable{
            width: 13%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.575em 0.825em 0.575em;
            background-color: white;
            border-radius: 13px;
            margin-right: 10px;
            text-align: center;
            cursor: pointer;
            box-shadow: 0px 3px 18px 0px rgba(24,24,25,0.1);
            transition: all 400ms ease-in-out;
            -webkit-backface-visibility: hidden;
            -webkit-transform: translate3d(0,0,0);
            &:hover, &.active{
                background-color: $yellowColor;
            }
            .icon{
                width: 2em;
                height: 2em;
                backface-visibility: hidden;
                will-change: transform, margin-top;
                transition: all 400ms linear;
                img{
                    object-fit: contain;
                }
            }
            span{
                font-size: 0.713em;
                color: black;
                font-weight: 600;
                white-space: nowrap;
                transition: all 400ms ease-in-out;
            }
        }
    }
    @media(min-width: 768px) {
        
    }
</style>
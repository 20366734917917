<template>
    <div class="left_block">
        <div class="icon">
            <span class="name">
                G
            </span>
        </div>
        <div class="menu">
            <ul>
                <li>
                    <router-link to="/" class="link_1"></router-link>
                </li>
                <li>
                    <router-link to="/history" class="link_2"></router-link>
                </li>
                <li>
                    <router-link to="1" class="link_3"></router-link>
                </li>
                <li>
                    <router-link to="2" class="link_4"></router-link>
                </li>
                <li>
                    <router-link to="3" class="link_5"></router-link>
                </li>
                <li>
                    <router-link to="4" class="link_6"></router-link>
                </li>
            </ul>
        </div>
        <div class="profile">
            <img src="@/assets/images/employees/1.jpg" alt="">
        </div>
    </div>
</template>

<script>
export default {
    setup () {
        

        return {}
    }
}
</script>

<style lang="scss" scoped>
    .left_block{
        padding: 1em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: $darkGrey;
        border-radius: 27px;
        width: 6.7%;
    }
    .icon{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3em;
        height: 3em;
        border-radius: 50%;
        background-color: $yellowColor;
        span{
            font-weight: 600;
            font-size: 1.313em;
        }
    }
    .profile{
        width: 3em;
        height: 3em;
        border-radius: 50%;
        overflow: hidden;
    }
    .menu{
        ul{
            margin: -0.6em 0;
        }
        a{
            width: 2.7em;
            height: 2.7em;
            margin: 0.8em 0;
            display: block;
            border-radius: 13px;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: 60%;
            opacity: 0.6;
            &.link_1{
                background-image: url('~@/assets/svg/Menu/1.svg');
            }
            &.link_2{
                background-image: url('~@/assets/svg/Menu/2.svg');
            }
            &.link_3{
                background-image: url('~@/assets/svg/Menu/3.svg');
            }
            &.link_4{
                background-image: url('~@/assets/svg/Menu/4.svg');
            }
            &.link_5{
                background-image: url('~@/assets/svg/Menu/5.svg');
            }
            &.link_6{
                background-image: url('~@/assets/svg/Menu/6.svg');
            }
            &:hover, &.router-link-exact-active{
                background-color: $buttonColor;
                opacity: 1;
            }
        }
    }
</style>
import axios from 'axios';
const apiLink = 'https://api-tuda-suda.sigma-studio.pp.ua/';
export async function getData() {
    try {
        const response = await axios.get(apiLink+'index.php?route=api/get&getData=products,categories');
        const data = response.data;
        return data;
    } catch (errors) {
        console.error(errors);
    }
}
<template>
    <div class="options">
        <div class="option" v-for="(option, index) in options" :key="option" @click="selectOption(index, option.product_option_value_id)" :ref="setItemRef">
            <div class="text">
                <span class="weight">{{option.name}} </span> <span class="prefix"> см</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'options',
        ],
        data() {
            return{ 
                elements: [],
            }
        },
        mounted(){
            if(this.elements[0]) {
                this.elements[0].classList.add("active");
            }
        },
        methods: {
            setItemRef(el) {
                if(el) {
                    this.elements.push(el);
                }
            },
            selectOption(active, option_id) {
                for(let i = 0; i < this.elements.length; i++) {
                    this.elements[i].classList.remove("active");
                }
                this.elements[active].classList.add("active");
                this.$emit('changeOption', option_id);
            }
        },
    }
</script>

<style lang="scss" scoped>
    .options{
        display: flex;
        justify-content: space-between;
        background-color: #1e1e1e;
        border-radius: 50px;
        padding: 3px;
        transform: translateY(-50%);
        .option{
            display: flex;
            width: 50%;
            border-radius: 50px;
            overflow: hidden;
            cursor: pointer;
            transition: all 400ms ease-in-out;
            &.active,&:hover{
                background-color: black;
            }
            .text{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                padding: 0.75em 0;
                font-weight: 500;
                text-align: center;
                font-size: 1.125em;
                .prefix{
                    padding-left: 0.2em;
                }
            }
        }
    }
</style>
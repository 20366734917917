export function filter(category_id, products) {
    products = products.filter(function (product){
        return product.categories.includes(category_id);
    });
    return products;
}

export function searchProducts(text, products) {
    return products.filter(function(data) {
        return data.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
    }).sort((item) => {
        return similar(item.name.toLowerCase(), text.toLowerCase())>50 ? -1 : 1;
    });
}

function similar(a,b) {
    let equivalency = 0;
    let minLength = (a.length > b.length) ? b.length : a.length;    
    let maxLength = (a.length < b.length) ? b.length : a.length;    
    for(let i = 0; i < minLength; i++) {
        if(a[i] == b[i]) {
            equivalency++;
        }
    }
    let weight = equivalency / maxLength;
    return (weight * 100);
}
<template>
    <div id="splashscreen">
        <div></div>
        <div></div>
    </div>
</template>

<script>
import gsap from 'gsap'
    export default {
        data: () => ({
            //loading: false
        }),
        methods: {
            start() {
                //this.loading = true;
                let splashscreen = document.getElementById("splashscreen");
                let tl = gsap.timeline();
                let children = splashscreen.children;
                setTimeout(() => {
                    tl.to(children[0], {
                        transformOrigin: "50% top",
                        duration: .8,
                        scaleY: 0,
                        ease: "power3.inOut"
                    })
                    .to(children[1], {
                        transformOrigin: "50% top",
                        duration: .8,
                        scaleY: 0,
                        ease: "power3.inOut",
                        onComplete: function() {
                            splashscreen.style.display = "none";
                        },
                    }, "-=0.4")
                }, 50);
            },
        }
    }
</script>

<style lang="scss" scoped>
    #splashscreen{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 999999999;
        overflow: hidden;
        div{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
            &:first-child{
                background-color: $yellowColor;
                z-index: 2;
            }
            &:last-child{
                background-color: $mainColor;
                z-index: 1;
            }
        }
    }
</style>